import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Storage } from '@capacitor/storage';
import { Device } from '@capacitor/device';
import { Token_Key, User_Key } from './appconst';

let apiBaseEndPoint = '';

const STAGING = 'https://buzreherbapi.profeworld.com/';
let REMOTE_HOST = 'https://buzreherbapi.profeworld.com/';

apiBaseEndPoint = STAGING;
if (environment.production) {
  apiBaseEndPoint = REMOTE_HOST;
}

let refreshLoginRoute = `${apiBaseEndPoint}/api/user/auth/sessionslogin.ashx`;
let loginroute = `${apiBaseEndPoint}/api/user/auth/login.ashx`;
let signUpRoute = `${apiBaseEndPoint}/api/user/auth/signup.ashx`;
let checkEmailRoute = `${apiBaseEndPoint}/api/user/auth/checkemail.ashx`;
let resetPasswordRoute = `${apiBaseEndPoint}/api/user/auth/forgotpassword.ashx`;

let updateProfileRoute = `${apiBaseEndPoint}/api/user/auth/updatedetails.ashx`;
let updatePasswordRoute = `${apiBaseEndPoint}/api/user/auth/resetpassword.ashx`;
let updateDpdRoute = `${apiBaseEndPoint}/api/user/auth/updatedp.ashx`;

@Injectable({
  providedIn: 'root',
})
export class AuthApiserviceService {
  authorization: string = '';
  header: any = {};
  resetPasswordToken: string = '';
  userDetails: IUserDetails = null;

  constructor(private httpClient: HttpClient) {
    // This is used to refresh Tokens and User details everytime this services is called or the page when this service is used is force Reload
    this.onLoad();
    // this.logout();
  }

  async onLoad() {
    // Load Default Headers for most of the Api
    this.header = await this.getheader();
    //this.loginToken = await this.getLoginToken();
    this.userDetails = await this.getUserdetails();
  }

  loginRefresh(): Observable<IUserData> {
    return this.httpClient.get<IUserData>(refreshLoginRoute, {
      headers: this.header,
    });
  }

  login(body: ILoginData): Observable<IUserData> {
    return this.httpClient.post<IUserData>(loginroute, body, {
      headers: this.header,
    });
  }

  signUp(body: any): Observable<IUserData> {
    return this.httpClient.post<IUserData>(signUpRoute, body, {
      headers: this.header,
    });
  }

  checkEmail(body: any): Observable<any> {
    return this.httpClient.post(checkEmailRoute, body, {
      headers: this.header,
    });
  }

  resetPassword(body: any): Observable<any> {
    body.token = this.resetPasswordToken;
    return this.httpClient.post(resetPasswordRoute, body, {
      headers: this.header,
    });
  }

  updateProfile(body: IUpdateProfileData): Observable<any> {
    return this.httpClient.patch(updateProfileRoute, body, {
      headers: this.header,
    });
  }

  updatePassword(body: IUpdatePasswordData): Observable<any> {
    return this.httpClient.post(updatePasswordRoute, body, {
      headers: this.header,
    });
  }

  updateDp(body: any): Observable<any> {
    return this.httpClient.patch(updateDpdRoute, body, {
      headers: this.header,
      responseType: 'json',
      reportProgress: true, // this is used to show the progress of upload
      observe: 'events', // this will help to print the refresh all observable including the progress
    });
  }

  /**
   *  This section are use to store User/Login Details and Token
   */
  async storeLoginToken(loginToken: any) {
    await Storage.set({
      key: Token_Key,
      value: loginToken,
    });
    // This update the Header with the Latest Token
    this.header = await this.getheader();
  }

  async storeUserdetails(userDetails: any) {
    await Storage.set({
      key: User_Key,
      value: JSON.stringify(userDetails),
    });
    // This update the Header with the User Details
    this.userDetails = await this.getUserdetails();
  }

  async logout() {
    await Storage.remove({ key: Token_Key });
    await Storage.remove({ key: User_Key });
    //  Set the latest state of the Auth Token and User Details
    this.header = await this.getheader();
    this.userDetails = await this.getUserdetails();
  }

  /**
   *  End of storage section
   */

  /**
   *  This section are use to get login details headers
   */

  async getUserdetails() {
    const { value } = await Storage.get({ key: User_Key });
    return JSON.parse(value);
  }

  async getLoginToken() {
    const { value } = await Storage.get({ key: Token_Key });
    return value;
  }

  async getheader() {
    const info = await Device.getId();
    this.authorization = await this.getLoginToken();

    let headers: any = {
      deviceuuid: info.uuid,
    };

    if (this.authorization) {
      headers = {
        ...headers,
        Authorization: '' + this.authorization + '',
      };
    }
    return headers;
  }
}

export interface ILoginData {
  email: string;
  password: string;
}

export interface ISignUpData {
  email: string;
  password: string;
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
}

export interface IUpdateProfileData {
  firstName: string;
  lastName: string;
  otherNames: string;
  phoneNumber: string;
  jobTitle: string;
  bio: string;
}

export interface IUpdatePasswordData {
  oldPassword: string;
  newPassword: string;
}

// export interface IUserData {
//   message?: string;
//   data?: {
//     token: string;
//     credentials: IUserDetails;
//   };
// }

// export interface IUserDetails {
//   userId: number;
//   email: string;
//   firstName: string;
//   lastName: string;
//   userName?: string;
//   phoneNumber?: string;
//   otherNames?: string | null;
//   fullName?: string;
//   emailVerified?: boolean;
//   dpUrl?: string | null;
//   jobTitle?: string;
//   bio?: string;
//   registrationDate?: string | Date;
// }

export interface IUserData {
  statusCode?: string;
  status?: string;
  data?: IData;
  moreInfo?: any;
}

export interface IData {
  token: string;
  user: IUserDetails;
}

export interface IUserDetails {
  userId: string;
  firstName: string;
  lastName: string;
  otherNames?: any;
  fullName: string;
  email: string;
  phoneNumber: string;
  dpUrl: string;
  verifyEmail: string;
  accounts: any[];
}
