import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  AuthApiserviceService,
  IUserData,
} from 'src/app/services/authapiservice.service';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { AuthmodalserviceService } from '../authmodal/service/authmodalservice.service';

@Component({
  selector: 'app-signupcomp',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss'],
})
export class SignupComponent implements OnInit {
  authData: IUserData = {};
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(
    private fb: FormBuilder,
    public authservice: AuthApiserviceService,
    public authModalservice: AuthmodalserviceService,
    public generalService: GeneralserviceService
  ) {
    this.logout();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      firstName: ['', [Validators.required]],
      lastName: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', [Validators.required, Validators.minLength(11)]],
      passWord: ['', [Validators.required, Validators.min(100000000000)]],
      confirmPassWord: ['', [Validators.required, Validators.minLength(8)]],
    });
  }

  async logout() {}

  async proceed() {
    // Get Value from the form in form of an object
    let formValue: any = this.myForm.value;

    // Remove the Confirm Password Property is not necessary
    delete formValue.confirmPassWord;

    // Add OtherNames Property it was not collected but needed in our Sign Up Body.
    formValue.otherNames = '';

    if (!this.myForm.valid) {
      this.generalService.presentToast('Form not valid, check your form input');
      return;
    }

    let formDataValue = this.getForm(formValue);

    this.startproceessing = true;

    this.authservice
      .signUp(formDataValue)
      .subscribe(
        (res) => {
          this.authData = res;
          // console.log(res);
          this.authData = res;
          // this.authservice.storeLoginToken(this.authData.data?.token);
          // this.authservice.storeUserdetails(this.authData.data?.user);
          this.generalService.presentToast('Your Account Has being created');
          this.popStatusCode(res['status']);
          this.authModalservice.canNavigate = false;
          this.navigate('');
        },
        (err) => {
          // console.log(err);
          this.popStatusCode(err['status']);
          this.generalService.presentToast(err.error?.message);
        }
      )
      .add(() => {
        this.startproceessing = false;
      });
  }

  async presentToast(msg) {}

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }

  getForm(data: any) {
    var formData = new FormData();
    const keys = Object.keys(data);
    keys.forEach((key) => {
      formData.append(key, data[key] ?? '');
    });

    return formData;
  }
}
