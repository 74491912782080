import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthApiserviceService } from './authapiservice.service';
import { loadingRequest } from './appconst';

let apiBaseEndPoint = '';

const STAGING = 'https://buzreherbapi.profeworld.com/';
let REMOTE_HOST = 'https://buzreherbapi.profeworld.com/';

apiBaseEndPoint = STAGING;
if (environment.production) {
  apiBaseEndPoint = REMOTE_HOST;
}

let refreshLoginRoute = `${apiBaseEndPoint}/api/v1/auth/sessions-login`;

let cartAddApi = `${apiBaseEndPoint}api/user/add/addcart.ashx`;
let cartDeleteApi = `${apiBaseEndPoint}api/user/delete/deletecart.ashx`;
let cartClearApi = `${apiBaseEndPoint}api/user/update/cart/clearcart.ashx`;
let cartUpdateApi = `${apiBaseEndPoint}api/user/update/cart/updatequantity.ashx`;
let cartApi = `${apiBaseEndPoint}api/user/view/viewcart.ashx`;

let orderAddApi = `${apiBaseEndPoint}/api/user/add/addorder.ashx`;
let orderApi = `${apiBaseEndPoint}/api/user/view/vieworders.ashx`;
let orderItemApi = `${apiBaseEndPoint}/api/v1/order-items`;

let wishListApi = `${apiBaseEndPoint}/api/v1/wishlists`;
let ReviewApi = `${apiBaseEndPoint}/api/v1/reviews`;

let coinBaseUrl = 'https://api.commerce.coinbase.com/charges';

@Injectable({
  providedIn: 'root',
})
export class PurchaseServiceService {
  public carts = [];
  public cartCount: number = 0;
  public cartAmount: number = 0;
  loadingStateData = loadingRequest; // Get the Loading State Date from the Loading Request Enum
  public cartLoadingState = loadingRequest.loading; // Set the Initial State to Loading
  coinBaseHeader;

  //Billing Form Details
  public billingFormValue: any;
  public billingFormValid: boolean;

  //Sample Cart Item
  cartItem = [
    {
      title: 'FirePip – Mentorship',
      image:
        'https://i0.wp.com/firepipsfx.com/wp-content/uploads/2021/10/FirePip-Mentorship.jpg?w=80&ssl=1',
      price: 280000,
      quantity: 2,
    },
  ];

  constructor(
    private httpClient: HttpClient,
    public authservice: AuthApiserviceService
  ) {
    this.loadCartDetails();
    this.loadCoinBaseHeader();
  }

  // Cart Region
  addCart(body: any): Observable<any> {
    // console.log(body);
    return this.httpClient.post(cartAddApi, body, {
      headers: this.authservice.header,
    });
  }

  getCarts(params: any): Observable<ICartList> {
    return this.httpClient.get<ICartList>(cartApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  getCartSingleItem(id: number | string): Observable<any> {
    return this.httpClient.get(`${cartApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  deleteCart(params: any): Observable<any> {
    return this.httpClient.post(cartDeleteApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  updateCart(params: any): Observable<any> {
    return this.httpClient.post(cartUpdateApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  clearCart(): Observable<any> {
    return this.httpClient.get(cartClearApi, {
      headers: this.authservice.header,
    });
  }

  // Wishlist Region
  addWishList(body: any): Observable<any> {
    return this.httpClient.post(wishListApi, body, {
      headers: this.authservice.header,
    });
  }

  getWishList(params: any): Observable<any> {
    return this.httpClient.get(wishListApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  getWishListSingleItem(id: number | string): Observable<any> {
    return this.httpClient.get(`${wishListApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  removeWishListCart(id: number | string): Observable<any> {
    return this.httpClient.delete(`${wishListApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  // Order Region
  addOrder(body: any): Observable<any> {
    return this.httpClient.post(orderAddApi, body, {
      headers: this.authservice.header,
    });
  }

  getOrders(params: any): Observable<any> {
    return this.httpClient.get(orderApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  getOrderSingle(id: number | string): Observable<any> {
    return this.httpClient.get(`${orderApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  getOrdersItems(params: any): Observable<any> {
    return this.httpClient.get(orderItemApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  getOrderItemSingle(id: number | string): Observable<any> {
    return this.httpClient.get(`${orderItemApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  // Review Region
  addReview(body: any): Observable<any> {
    return this.httpClient.post(ReviewApi, body, {
      headers: this.authservice.header,
    });
  }

  getReviews(params: any): Observable<any> {
    return this.httpClient.get(ReviewApi, {
      headers: this.authservice.header,
      params: params,
    });
  }

  getReviewSingleItem(id: number | string): Observable<any> {
    return this.httpClient.get(`${ReviewApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  deleteReview(id: number | string): Observable<any> {
    return this.httpClient.delete(`${ReviewApi}/${id}`, {
      headers: this.authservice.header,
    });
  }

  // Other Region
  initCoinBasePay(body: any): Observable<any> {
    return this.httpClient.post(coinBaseUrl, body, {
      headers: this.coinBaseHeader,
    });
  }

  // Get Cart Cart
  async loadCartDetails() {
    await this.authservice.onLoad();
    await this.getCarts({})
      .subscribe(
        (res) => {
          // console.log(res);
          if (!res) {
            this.cartLoadingState = loadingRequest.noData;
            return;
          }
          // console.log(res.data);
          this.carts = res.data;
          this.cartAmount = res.moreInfo.cartTotal.total;
          this.cartCount = res.moreInfo.cartTotal.noOfItems;
          this.cartLoadingState = loadingRequest.success;
        },
        (err) => {
          this.cartLoadingState = loadingRequest.error;
          // console.log(err);
        }
      )
      .add(() => {});
  }

  // Get Coinbase Header
  async getheaderCoinBase() {
    let headers = {
      'X-CC-Api-Key': '15778765-2a56-4a4a-a15e-1ea147a75e2a',
      'X-CC-Version': '2018-03-22',
    };
    return headers;
  }

  async loadCoinBaseHeader() {
    this.coinBaseHeader = await this.getheaderCoinBase();
  }
}

// Types for Purchase
export type IWishList = {
  message: string;
  data: IWishListData[];
  meta: {
    total: number;
  };
};

export type IWishListData = {
  wishlistId: number;
  userId: number;
  type: string;
  typeId: number;
  title: string;
  imgUrl: string;
  itemId?: number;
  dateModified?: string;
  dateCreated?: string;
};

export type IOrders = {
  message: string;
  data: IOrdersData[];
  meta: {};
};

export type IOrdersData = {
  orderId: number;
  user: {
    id: number;
    fullName: string;
  };
  amount: any;
  verified: boolean;
  reference: any;
  itemsPrice: number;
  currency: any;
  paymentGateway: any;
  paidAt: any;
  orderItems?: IOrderItemData | any;
  billInfo: {
    firstName: string;
    lastName: string;
    email: string;
    username: null;
    phoneNumber: string;
    country: string;
    city: string;
  };
  dateModified?: string;
  dateCreated?: string;
};

export type IOrderItems = {
  message: string;
  data: IOrderItemData[];
  meta: {
    total: number;
  };
};

export type IOrderItemData = {
  orderItemId: number;
  orderId?: number;
  verified?: boolean;
  title: string;
  imgUrl: string;
  amount: number;
  type: string;
  dateModified?: string;
  dateCreated?: string;
};

export type IReviews = {
  message: string;
  data: IReviewData[];
  meta: {
    total: number;
  };
};

export type IReviewData = {
  reviewId: number;
  user: {
    userId: any;
    fullName: string;
    imgUrl: string;
  };
  course: {
    id: number;
    title: string;
    imgUrl: string;
  };
  description: string;
  edited: boolean;
  dateModified: string;
  dateCreated: string;
};

// Cart Types
export interface ICartList {
  statusCode: string;
  status: string;
  data: ICartdata[];
  moreInfo: MoreInfoCart;
}

interface MoreInfoCart {
  total: number;
  page: number;
  perPage: number;
  hasData: boolean;
  cartTotal: CartTotal;
}

interface CartTotal {
  itemsTotal: number;
  supplementsTotal: number;
  extraFee: number;
  total: number;
  noOfItems: number;
}

interface ICartdata {
  cartId: string;
  userId: string;
  itemId: string;
  itemName: string;
  itemPrice: string;
  itemImg: string;
  quantity: string;
  amount: string;
  vendorId?: any;
  vendorBusiness?: any;
  additionalNotes?: any;
  optionsAmount: string;
  supplementOptions: SupplementOption[];
  supplementOptionsText: string[];
  supplements: Supplement[];
  dateModified: string;
  dateCreated: string;
  totalRows: string;
}

interface Supplement {
  itemSupplementId: string;
  supplementTitle: string;
  supplementDescription: string;
  multiple: string;
  options: SupplementOption[];
}

interface SupplementOption {
  optionid: string;
  name: string;
  isDefault: boolean;
  selected: boolean;
  additionalPrice: string;
  imageUrl?: any;
}
// End Cart Types
