import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-setnewpassword',
  templateUrl: './setnewpassword.component.html',
  styleUrls: ['./setnewpassword.component.scss'],
})
export class SetnewpasswordComponent implements OnInit {
  myForm: FormGroup = new FormGroup({});
  startproceessing = false;

  @Output() loginStatusCode: EventEmitter<any> = new EventEmitter();
  @Output() reDirectionPage: EventEmitter<any> = new EventEmitter();

  constructor(private fb: FormBuilder) {
    this.logout();
  }

  ionViewDidEnter() {
    this.startproceessing = false;
    this.logout();
  }

  ngOnInit() {
    this.myForm = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
    });
  }

  async logout() {}

  async proceed() {
    // console.log('Valid?', this.myForm.valid);
    // console.log(this.myForm.controls);
    // console.log(this.myForm.value);
    this.navigate('');
    this.popStatusCode(200);
  }

  async presentToast(msg) {}

  navigate(pagename) {
    this.reDirectionPage.emit(pagename);
  }

  popStatusCode(index) {
    this.loginStatusCode.emit(index);
  }
}
