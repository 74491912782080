import { Component, OnInit, ViewChild } from '@angular/core';
import {
  LoadingController,
  MenuController,
  NavController,
  ToastController,
} from '@ionic/angular';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-sidemenu',
  templateUrl: './sidemenu.component.html',
  styleUrls: ['./sidemenu.component.scss'],
})
export class SidemenuComponent implements OnInit {
  pathname = '';
  public appPages = [
    {
      title: 'Dashboard',
      url: '/dashboard',
      icon: 'home-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'My Profile',
      url: '/managestudent',
      icon: 'person-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Enrolled Course',
      url: '/manageteacher',
      icon: 'book-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'WishList',
      url: '/#',
      icon: 'heart-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Review',
      url: '/#',
      icon: 'star-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'My Quiz Attempt',
      url: '/managecourse',
      icon: 'help-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Order History',
      url: '/#',
      icon: 'cart-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Question and Answer',
      url: '/managequiz',
      icon: 'laptop-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Settings',
      url: '/manageresult',
      icon: 'cog-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
    {
      title: 'Logout',
      url: '/logout',
      icon: 'log-out-outline',
      imageurl: 'assets/images/svg/home.svg',
      subtitle: 'View Now',
      label: false,
    },
  ];

  constructor(
    public http: HttpClient,
    public toastController: ToastController,
    private router: Router
  ) {
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  getpathname() {
    this.pathname = window.location.pathname;
  }
}
