import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MenuController } from '@ionic/angular';
import { AuthmodalserviceService } from '../../auth-comp/authmodal/service/authmodalservice.service';
import { PurchaseServiceService } from 'src/app/services/purchase-service.service';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';

@Component({
  selector: 'app-landingmenumobile',
  templateUrl: './landingmenumobile.component.html',
  styleUrls: ['./landingmenumobile.component.scss'],
})
export class LandingmenumobileComponent implements OnInit {
  pathname = '';

  constructor(
    private router: Router,
    public authModalService: AuthmodalserviceService,
    public authservice: AuthApiserviceService,
    public purchaseService: PurchaseServiceService,
    public menu: MenuController
  ) {
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  getpathname() {
    this.pathname = window.location.pathname;
  }

  toogleMenu() {
    this.menu.toggle();
  }

  /* Set the width of the sidebar to 250px (show it) */
  openNav() {
    // console.log('Open Menu');
    document.getElementById('mySidepanelbar').style.width = '100%';
    document.getElementById('mySidepanel').style.width = '250px';
  }

  /* Set the width of the sidebar to 0 (hide it) */
  closeNav() {
    // console.log('Close Menu');
    document.getElementById('mySidepanelbar').style.width = '0';
    document.getElementById('mySidepanel').style.width = '0';
  }
}
