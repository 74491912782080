import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthmodalserviceService } from '../../auth-comp/authmodal/service/authmodalservice.service';
import { PurchaseServiceService } from 'src/app/services/purchase-service.service';
import { AuthApiserviceService } from 'src/app/services/authapiservice.service';

@Component({
  selector: 'app-landingmenu',
  templateUrl: './landingmenu.component.html',
  styleUrls: ['./landingmenu.component.scss'],
})
export class LandingmenuComponent implements OnInit {
  @Input() fixedMenu = false;
  pathname = '';

  constructor(
    private router: Router,
    public authModalService: AuthmodalserviceService,
    public authservice: AuthApiserviceService,
    public purchaseService: PurchaseServiceService
  ) {
    router.events.subscribe((val) => {
      this.getpathname();
    });
  }

  ngOnInit() {}

  getpathname() {
    this.pathname = window.location.pathname;
  }
}
