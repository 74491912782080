export const Token_Key = 'token';
export const User_Key = 'userDetails';
export const Logo_Image = 'assets/images/logo.png';

export enum loadingRequest {
  idle = 0,
  loading = 1,
  success = 2,
  noData = 3,
  error = 4,
  unknownException = 5,
}
