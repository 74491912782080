import { Injectable } from '@angular/core';
import {
  AlertController,
  AlertInput,
  NavController,
  ToastController,
} from '@ionic/angular';

@Injectable({
  providedIn: 'root',
})
export class GeneralserviceService {
  constructor(
    public toastController: ToastController,
    public alertController: AlertController,
    public navctrl: NavController
  ) {}
  async presentToast(
    message: string,
    header?: string,
    duration?: any,
    cssClass?:
      | 'toast-custom-class'
      | 'toast-custom-class-error'
      | 'toast-custom-class-success'
      | 'toast-custom-class-warning'
      | 'toast-custom-class-info'
  ) {
    const toast = await this.toastController.create({
      header: header || '',
      message: message,
      duration: duration || 5000,
      cssClass: cssClass || 'toast-custom-class', // css class are defined on the global.scss file
      buttons: [
        {
          side: 'end',
          icon: 'close-circle',
          handler: () => {},
        },
      ],
    });

    await toast.present();
  }

  async presentBasicAlert(header, subheader, message, callBack: () => {}) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: [
        {
          text: 'Okay',
          role: 'cancel',
          handler: callBack,
        },
      ],
    });
    await alert.present();
  }

  async presentBasicAlertDialog(
    header,
    subheader,
    message,
    btnSuccesName: string,
    btnCancelName: string,
    successcallBack: () => void,
    cancelcallBack: () => void
  ) {
    const alert = await this.alertController.create({
      header: header,
      subHeader: subheader,
      message: message,
      buttons: [
        {
          text: btnSuccesName,
          role: 'okay',
          handler: successcallBack,
        },
        {
          text: btnCancelName,
          role: 'cancel',
          handler: cancelcallBack,
        },
      ],
    });
    await alert.present();
  }

  async PresentBasicPrompt(
    title?: string,
    btnSuccessName?: string,
    inputs?: AlertInput[],
    callBack?: (data: any) => void
  ) {
    const alert = await this.alertController.create({
      header: title,
      buttons: [
        {
          text: btnSuccessName,
          handler: callBack,
        },
      ],
      inputs: inputs,
    });

    await alert.present();
  }

  navigatePage(reDirectionPage) {
    this.navctrl.navigateRoot(reDirectionPage);
  }
}
