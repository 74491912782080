import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { RouterModule } from '@angular/router';
import { SidemenuComponent } from './sidemenu/sidemenu.component';
import { LandingmenuComponent } from './landingmenu/landingmenu.component';
import { LandingfooterComponent } from './landingfooter/landingfooter.component';
import { LandingmenumobileComponent } from './landingmenumobile/landingmenumobile.component';

@NgModule({
  declarations: [
    SidemenuComponent,
    LandingmenuComponent,
    LandingmenumobileComponent,
    LandingfooterComponent,
  ],
  imports: [IonicModule, RouterModule, CommonModule],
  exports: [
    SidemenuComponent,
    LandingmenuComponent,
    LandingmenumobileComponent,
    LandingfooterComponent,
  ],
})
export class LayoutModule {}
