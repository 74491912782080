import { Injectable } from '@angular/core';
import { GeneralserviceService } from 'src/app/services/generalservice.service';
import { authComponentType } from '../../authtypes.modal';

@Injectable({
  providedIn: 'root',
})
export class AuthmodalserviceService {
  currentpage: authComponentType;
  canNavigate = true;

  constructor(public generalService: GeneralserviceService) {}

  navigateToPage($event) {
    this.currentpage = $event;
    // Move to an external page If Can Navigate Is True else close the modal
    if (!this.isValidTabType(this.currentpage)) {
      if (this.canNavigate) {
        if (this.currentpage.toString() == '') {
          return;
        }
        this.generalService.navigatePage(this.currentpage);
      }
      this.canNavigate = true;
    }
  }

  isValidTabType(pageName: string): boolean {
    const options: string[] = Object.values(authComponentType);
    return options.includes(pageName);
  }
}
