import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthguardGuard } from './guards/authguard.guard';
import { LoginguardGuard } from './guards/loginguard.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'dashboard',
    loadChildren: () =>
      import('./pages/dashboard/home/home.module').then(
        (m) => m.HomePageModule
      ),
    canLoad: [AuthguardGuard],
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/landingpage/home/home.module').then(
        (m) => m.HomePageModule
      ),
  },
  {
    path: 'authpage',
    loadChildren: () =>
      import('./pages/auth/authpage/authpage.module').then(
        (m) => m.AuthpagePageModule
      ),
    canLoad: [LoginguardGuard],
  },
  {
    path: 'logout',
    loadChildren: () =>
      import('./pages/dashboard/logout/logout.module').then(
        (m) => m.LogoutPageModule
      ),
  },
  {
    path: 'products',
    loadChildren: () =>
      import('./pages/landingpage/products/products.module').then(
        (m) => m.ProductsPageModule
      ),
  },
  {
    path: 'product/:id/:title',
    loadChildren: () =>
      import(
        './pages/landingpage/product-sales-page/product-sales-page.module'
      ).then((m) => m.ProductSalesPagePageModule),
  },
  {
    path: 'create-mood',
    loadChildren: () =>
      import('./pages/landingpage/create-mood/create-mood.module').then(
        (m) => m.CreateMoodPageModule
      ),
  },
  {
    path: 'reherb',
    loadChildren: () =>
      import('./pages/landingpage/reherb/reherb.module').then(
        (m) => m.ReherbPageModule
      ),
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./pages/landingpage/cart/cart.module').then(
        (m) => m.CartPageModule
      ),
  },
  {
    path: 'checkout',
    loadChildren: () =>
      import('./pages/landingpage/checkout/checkout.module').then(
        (m) => m.CheckoutPageModule
      ),
  },
  {
    path: 'checkout-confirm',
    loadChildren: () =>
      import(
        './pages/landingpage/checkout-confirm/checkout-confirm.module'
      ).then((m) => m.CheckoutConfirmPageModule),
  },
  {
    path: 'test-page',
    loadChildren: () => import('./pages/landingpage/test-page/test-page.module').then(m => m.TestPagePageModule)
  },
  {
    path: 'page-not-found',
    loadChildren: () => import('./pages/landingpage/page-not-found/page-not-found.module').then(m => m.PageNotFoundPageModule)
  },
  {
    path: 'anxiety-relief',
    loadChildren: () => import('./pages/landingpage/anxiety-relief/anxiety-relief.module').then(m => m.AnxietyReliefPageModule)
  },
  {
    path: 'product-category',
    loadChildren: () => import('./pages/landingpage/product-category/product-category.module').then(m => m.ProductCategoryPageModule)
  },
  {
    path: 'reherb-main',
    loadChildren: () => import('./pages/landingpage/reherb-main/reherb-main.module').then(m => m.ReherbMainPageModule)
  },
  {
    path: 'reherb-main-therapist-questions',
    loadChildren: () => import('./pages/landingpage/reherb-main-therapist-questions/reherb-main-therapist-questions.module').then(m => m.ReherbMainTherapistQuestionsPageModule)
  },
  {
    path: 'reherb-main-therapist-questions/:id',
    loadChildren: () => import('./pages/landingpage/reherb-main-therapist-questions/reherb-main-therapist-questions.module').then(m => m.ReherbMainTherapistQuestionsPageModule)
  },
  {
    path: 'reherb-medical-condition',
    loadChildren: () => import('./pages/landingpage/reherb-medical-condition/reherb-medical-condition.module').then(m => m.ReherbMedicalConditionPageModule)
  },
  {
    path: 'reherb-desire-relief',
    loadChildren: () => import('./pages/landingpage/reherb-desire-relief/reherb-desire-relief.module').then(m => m.ReherbDesireReliefPageModule)
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
